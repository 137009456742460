.accordion {
  padding-bottom: 60px;

  .accordion-item {
    .accordion-title {
      color: $green;
      font-size: rem-calc(22);
      padding: 15px 0;
      padding-right: 20px;
      border-bottom: 1px solid $grey-20 !important;
      &:before {
        right: 0;
        top: 30px;
        content: "";
        background-image: url("../img/2x/icons/icn_accordion_medium_down_2x.png");
        background-size: 100%;
        width: 10px;
        height: 10px;
      }
    }
    &.is-active > .accordion-title {
      &::before {
        background-image: url("../img/2x/icons/icn_accordion_medium_up_2x.png");
      }
    }
    .accordion-content {
      padding: 30px 0 0 0;
      .table-element-container {
        padding-bottom: 40px;
      }
      h2,
      .h2 + .table-element-container {
        margin-top: 15px;
      }
      .slider-container {
        h2,
        .h2 + .table-element-container {
          margin-top: 0;
        }
      }
    }

    &:last-child {
      .accordion-content {
        padding-bottom: 0;
      }
    }
  }
  &.small {
    .accordion-item {
      .accordion-title {
        font-size: rem-calc(16);
        color: $grey-80;
        padding: 8px 0;
        .second-column {
          color: $green;
          font-weight: bold;
          display: block;
          padding-top: 5px;
        }
        .third-column {
          padding-top: 5px;
          display: block;
        }
        &:before {
          top: 30px;
          content: "";
          background-image: url("../img/2x/icons/icn_accordion_small_down_2x.png");
          background-size: 100%;
          width: 7px;
          height: 5px;
          margin-top: -14px;
        }
        @include breakpoint(medium up) {
          .second-column {
            position: absolute;
            left: 240px;
            top: 3px;
          }
        }
        @include breakpoint(xlarge up) {
          .third-column {
            position: absolute;
            left: 600px;
            top: 3px;
          }
        }
      }
      &.is-active > .accordion-title {
        &::before {
          background-image: url("../img/2x/icons/icn_accordion_small_up_2x.png");
        }
      }
      .accordion-content {
        padding: 15px 0;
      }
    }
  }
  &.accordion-account-data {
    padding-bottom: 40px;
  }
}
