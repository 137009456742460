form {
  *:disabled,
  *.disabled {
    pointer-events: none;
  }

  label {
    font-size: rem-calc(14);
    width: 100%;
    @include breakpoint(medium up) {
      width: 120%;
    }
  }

  input[type="text"],
  input[type="tel"],
  input[type="email"],
  textarea,
  select,
  .chosen-container-single .chosen-single {
    margin-bottom: 10px;
    height: 44px;
    text-overflow: ellipsis;
    border: 1px solid $grey-60;
    color: $grey-80;
    padding-left: 6px;
    &.icon {
      padding-left: 35px;
    }
    + .icon {
      position: absolute;
      left: 20px;
      top: 13px;
    }
    &.search-mobile {
      border: none;
      height: 40px;
      padding-left: 25px;
      width: 95%;
      margin-bottom: 0;
    }
    &.search-desktop {
      border: 1px solid $grey-20;
      height: 35px;
      padding-left: 32px;
      padding-right: 32px;
      width: 100%;
      margin-bottom: 0;
      line-height: rem-calc(20px);
    }
    &.small-version {
      height: 30px;
      font-size: 14px;
      padding: 5px 25px 5px 4px;
      border: 1px solid $grey-20;
    }
    &.textarea {
      height: 25vh;
    }
  }

  input[type="text"],
  textarea,
  input[type="tel"],
  input[type="email"] {
    &.error {
      border-color: $red-error;
    }
  }

  .error {
    input[type="text"],
    textarea,
    input[type="password"],
    input[type="tel"],
    input[type="email"] {
      border-color: $red-error;
    }
  }

  .loading {
    opacity: 0.5;
    cursor: default !important;
    background-color: #707173 !important;
    color: $white !important;
    &:before {
      content: " ";
      background: url("../img/ajax-loader.gif") no-repeat;
      width: 16px;
      height: 16px;
      background-size: 16px;
      position: absolute;
      right: 16px;
      top: 50%;
      margin-top: -8px;
    }
  }
  // .phone_codes {
  //   max-width: 125px;//
  // }
  input[readonly],
  textarea[readonly] {
    background-color: $grey-20;
    pointer-events: none;
  }

  textarea {
    resize: none;
  }

  select {
    &.umkreis {
      border-left: 0px;
    }
  }

  /*
    *   ICHECK
    */

  .icheckbox {
    @include image-png("icons/checkbox", 20px, 20px, 20px, 100px);
  }

  .iradio {
    @include image-png("icons/radio", 20px, 20px, 20px, 100px);
  }

  .icheckbox,
  .iradio {
    &.hover {
      background-position: 0 -20px;
    }

    &.checked {
      background-position: 0 -40px;
    }

    &.checked.hover {
      background-position: 0 -60px;
    }

    &.error {
      background-position: 0 -80px;
    }
  }

  /*
    *   CHOSEN
    */
  .chosen-container {
    width: 100% !important;
    font-size: $size-16;
    .chosen-results {
      color: $grey-80;
      margin: 0;
      padding: 0;
      background-color: $white;
      li {
        padding-left: 10px;
        line-height: 30px;
        &.highlighted {
          background-color: $green;
          background-image: none;
        }
      }
    }
    &.umkreis {
      .chosen-results {
        margin-left: -2px !important;
        border-left: 1px solid $grey-60 !important;
      }
    }
    &.chosen-with-drop {
      .chosen-drop {
        margin-top: -11px;
      }
    }

    &.chosen-container-single-nosearch {
      .chosen-search {
        display: none;
      }
    }
  }

  .chosen-container-single {
    &.chosen-container-active {
      &.chosen-with-drop {
        .chosen-single {
          div {
            b {
              background-position: 0 -10px;
            }
          }
        }
      }
    }
    .chosen-single,
    .chosen-drop {
      border-radius: 0;
      background: $white;
      box-shadow: none;
      padding: 8px 5px 0 10px;
    }
    .chosen-single {
      div {
        b {
          @include image-png("icons/chosen-sprite", 10px, 10px, 10px, 20px);
          background-position: 0 0;
          margin-top: 15px;
        }
      }
    }
    .chosen-drop {
      padding: 0;
    }
    .chosen-search {
      padding-left: 10px;
    }
    &.umkreis {
      .chosen-single {
        border-left: 0;
      }
    }
  }
  .chosen-container-active {
    &.chosen-with-drop {
      .chosen-single {
        background-image: none;
      }
    }
  }

  //Select small-version
  .chosen-select {
    &.small-version {
      .chosen-single,
      .chosen-container-single {
        height: 30px;
        font-size: 14px;
        padding: 2px 25px 5px 8px;
        border: 1px solid $grey-20;
        div {
          b {
            @include image-png(
              "icons/chosen-sprite-small",
              10px,
              10px,
              10px,
              40px
            );
            background-position: 0 0;
            margin-top: 10px;
          }
        }
        &.chosen-container-active {
          div {
            b {
              background-position: 0 -20px;
            }
          }
        }
      }
      &.chosen-container-single {
        &.chosen-container-active {
          &.chosen-with-drop {
            .chosen-single {
              div {
                b {
                  background-position: 0 -20px;
                }
              }
            }
          }
        }
      }
      &.chosen-container {
        .chosen-drop {
          border: 1px solid $grey-20;
          margin-top: -1px;
          li {
            font-size: rem-calc(14px);
          }
        }
        .chosen-results {
          li {
            line-height: 20px;
          }
        }
      }
      &.chosen-container-single {
        .chosen-single {
          margin-bottom: 0;
        }
      }
    }
    .chosen-single {
    }
  }
  //Select small-version
  .error {
    .chosen-select {
      .chosen-single {
        border-color: $red-error;
      }
    }
  }

  &.contact-form {
    input[type="text"],
    textarea {
      border-color: $white;

      &.error {
        border-color: $alert-color;
      }
    }
  }
  .recaptcha {
    padding-bottom: 10px;
  }

  .g-recaptcha {
    transform: scale(1.1) !important;
    transform-origin: 0 0;
    margin-bottom: 10px;

    @media only screen and (max-width: 387px) {
      transform: scale(1) !important;
    }
  }

  .info-text {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 14px;
    display: inline-block;
  }
  .no-margin {
    input {
      margin: 0px;
    }
  }
}

.contact-form {
  &.form-success {
    font-size: rem-calc(20px);
    font-weight: bold;
    line-height: rem-calc(24px);
  }
}

// Radio

/* Container */
.container-radio {
  display: block;
  position: relative;
  cursor: pointer;
  border-radius: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  //add transition and styles
  padding-left: 35px; //Width of clickarea + margin
  margin-bottom: 10px;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    margin: 0;

    /* When the checkbox is checked */
    &:checked {
      ~ .checkmark {
        border-color: $green;
        color: $secondary-color;

        /* Show the checkmark when checked */
        &:after {
          display: block;
          background-color: $green;
        }
      }
    }

    /* Error */
    &.error {
      ~ .checkmark {
        border-color: $red-error;
        color: $red-error;

        /* Show the checkmark when checked */
        &:after {
          background-color: $red-error;
          display: none;
        }
      }
    }
  }

  /* On mouse-over */
  &:hover {
    input {
      ~ .checkmark {
        border-color: $green;
        color: $grey-80;

        &:after {
          background-color: $grey-80;
        }
      }

      &:checked {
        ~ .checkmark {
          border-color: $green;
          color: $grey-80;

          &:after {
            background-color: $green;
          }
        }
      }
    }
  }

  /* Style the checkmark/indicator */
  .checkmark {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0%, -50%);
    margin-top: 0;
    border-radius: 100%;

    //Style outer circle
    height: 22px;
    width: 22px;
    background-color: $white;
    border: 1px solid $grey-60;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;

      //Style inner circle
      width: 10px;
      height: 10px;
      background-color: $green;
    }
  }
}
