// SITE FOOTER

.site-footer {
    min-height: $footer-height;
    border-top: 1px solid $grey-20;
    padding-top: 10px;
    padding-bottom: 15px;
    font-size: $size-14;
    color: $grey-60;
    .copyright{
        text-align: center;
        display: block;
        padding: 5px 0px;
        @include breakpoint(medium up) {
            text-align: left;
        }
    }
    ul{
        margin: 0;
        text-align: center;
        @include breakpoint(medium up) {
            text-align: right;
        }
        li{
            list-style: none;
            text-align: center;
            display: inline-block;
            padding-top: 5px;
            padding-bottom: 5px;

            &:after{
                content:'|';
                display: inline;
                margin-left: 10px;
                margin-right: 10px;
            }

            &:last-child{
                &:after{
                    display: none;
                }
            }

            @include breakpoint(medium up) {
                text-align: right;
            }
            a{
                color: $grey-60;
                &:hover{
                    color: $grey-80;
                }
            }
        }
    }
}