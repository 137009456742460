
.top-layer{
    padding-top: rem-calc(15);
    padding-bottom: rem-calc(15);

    margin-bottom: rem-calc(35);
}

.steps-wrapper{
    @include clearfix;
    margin-bottom: rem-calc(20);

    li{
        float:left;

        .line{
            display: block;
            width: 40px;
            border-top: 1px solid $grey-20;
            margin-top: calc((60px / 2) - 1px);
        }

        a{
            color: $white;
            background-color: $green-60;
            width: 60px;
            height: 60px;
            border-radius: 100%;
            position: relative;
            display: block;
            font-weight: bold;
            font-size: rem-calc(24);
            cursor: default;

            .number{
                @include absolute-center;
            }
        }


        &.is-active{
            a{
                background-color: $green;  
            }
        }
        &.disabled{
            a{
                background-color: $grey-20;  
            }
        }
    }
}