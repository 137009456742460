.intro{
    h1, .h1{
        margin-bottom: 0;
    }
    .accordion{
        padding-bottom: 20px;
    }
}

.section-headline{
    border-bottom: 1px solid $grey-20;
    margin-bottom: 10px;
    padding-bottom: 5px;
    h3, .h3{
        color: $green;
    }

}