/*
*   MISC
*/

$footer-height: 58px;

/*
*   FORM
*/

$input-height: 1.125rem; //18px
$input-border: 1px solid $black;



/*
*   PATHS
*/

$image-path: "../img";
$retina-image-path: "../img/2x";



/*
*   SIZES
*/
$size-14: 0.875rem;
$size-16: 1rem;
$size-18: 1.125rem;
$size-20: 1.25rem;  
$size-22: 1.375rem;
$size-24: 1.5rem;
$size-26: 1.625rem;
$size-28: 1.75rem;
$size-30: 1.875rem;  
$size-32: 2rem;


/*
*   Wrapper page
*/
$wrapper-page-padding: (
    small: 5px,
    medium: 10px
);