
/*
*   FONTS
*/

@mixin font-reg {
    font-family: $font-reg, $fallback-fonts;
}

@mixin font-bold {
    font-family: $font-bold, $fallback-fonts;
}

@mixin font-light {
    font-family: $font-light, $fallback-fonts;
}


/*
*   IMAGES, RETINA IMAGES
*/

/* Images */
$image-path: "../img";

/* Retina */
$retina: "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 2dppx)";
$retina-image-path: "../img/2x";
$retina-image-suffix: "2x";


// Mixin für PNG-Bild/Icon mit Retinadarstellung
// Parameter Bildpfad, Breite und Höhe (vom Bildausschnitt); optional Breite und Höhe von der gesamten Grafik
// Beispiel: @include image-png('logo', 140px, 40px);
@mixin image-png ($image, $width, $height, $retinaWidth:100%, $retinaHeight:auto) {
    background-image:url("#{$image-path}/#{$image}.png");
    background-repeat:no-repeat;
    width:$width;
    height:$height;
    display:inline-block;
    @include retina-image-png($image, $retinaWidth, $retinaHeight);
}

// Mixin für JPG-Bild/Icon mit Retinadarstellung
// Parameter Bildpfade, Breite, Höhe
// Beispiel: @include image-jpg('logo', 140px, 40px);
@mixin image-jpg ($image, $width, $height) {
    background-image:url("#{$image-path}/#{$image}.jpg");
    background-repeat:no-repeat;
    width:$width;
    height:$height;
    display:inline-block;
    @include retina-image-jpg($image, 100%, auto);
}

@mixin retina-image-png($image, $width, $height) {
    @media #{$retina} {
        background-image:url("#{$retina-image-path}/#{$image}_#{$retina-image-suffix}.png");
        background-size:$width $height;
    }
}

@mixin retina-image-jpg ($image, $width, $height) {
    @media #{$retina} {
        background-image:url("#{$retina-image-path}/#{$image}_#{$retina-image-suffix}.jpg");
        background-size:$width $height;
    }
}


@mixin chosen-sprite{
    @include image-png('icons/chosen-sprite', 24px, 40px);
    background-position: 0 16px;
}


/*
*   PLACEHOLDER
*/

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}  
}

/* Cut Corner */
@mixin cut-corner($background-color, $size) {
    position:relative;
    &::after {
        content: '';
        position: absolute;
        background:$background-color;
        width:$size;
        height:$size;
        -webkit-transform:rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        right:-($size/2);
        bottom:-($size/2);
    }
}

