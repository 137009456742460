/*
*   HTML, BODY
*/

html,
body {
  height: 100%;
}

body {
  background-image: url("../img/bg.jpg");
  background-repeat: repeat;
  background-position: center top;
}

.page-wrap {
  min-height: 100%;
  width: 100%;
  height: 100%;
  display: table;
  //margin-bottom: -$footer-height; Stickyfooter
}

.center-page {
  width: 100%;
  display: table-cell;
  vertical-align: middle;
}

.maxwidth-content {
  position: relative;
  max-width: rem-calc(980);
  margin-left: auto;
  margin-right: auto;
  background-color: $white;
  margin-top: rem-calc(20);
  margin-bottom: rem-calc(20);
}

.page-content {
  margin-top: rem-calc(40);
  margin-bottom: rem-calc(60);
}
