.link-bar-container{
    margin-bottom: 30px;
    border-bottom: 1px solid $grey-20;
    float: left;
    width: 100%;

    &.back{
        padding-top: 10px;
        border-top: 1px solid $grey-20;
        border-bottom: none;
        margin-top: 30px;
        margin-bottom: 0;
        .back-link{
            border-bottom: none;
            margin-top: 4px;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
}