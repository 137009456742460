// ICONS

.icon {
  &.icn-search {
    @include image-png("icons/icn_search", 15px, 15px);
  }
  &.icn-menu {
    @include image-png("icons/icn_menu", 15px, 20px, 15px, 40px);
  }
  &.icn-menu-close {
    @include image-png("icons/icn_menu", 15px, 20px, 15px, 40px);
    background-position: 0 -20px;
  }
  &.icn-close {
    @include image-png("icons/icn_close_small", 12px, 12px, 12px, 48px);

    &.green {
      background-position: 0 0;
    }
    &.gray {
      background-position: 0 -12px;
    }
    &.light-grey {
      background-position: 0 -24px;
    }
    &.white {
      background-position: 0 -36px;
    }
  }
  &.icn-socials-tw {
    @include image-png("icons/icn_socials_tw", 15px, 15px, 15px, 30px);
  }
  &.icn-socials-fb {
    @include image-png("icons/icn_socials_fb", 15px, 15px, 15px, 30px);
  }
  &.icn-socials-mail {
    @include image-png("icons/icn_socials_mail", 15px, 15px, 15px, 30px);
  }
  &.icn-arrow-medium-right {
    @include image-png("icons/icn_arrow_medium_right", 10px, 10px, 10px, 50px);
  }
  &.icn-arrow-medium-left {
    @include image-png("icons/icn_arrow_medium_left", 10px, 10px, 10px, 40px);
  }
  &.icn-arrow-medium-updown {
    @include image-png("icons/icn_arrow_medium_updown", 10px, 10px, 30px, 40px);
  }
  &.icn-moreless {
    @include image-png("icons/icn_moreless", 10px, 10px, 30px, 40px);
  }
  &.icn-location {
    @include image-png("icons/icn_location", 10px, 15px);
  }
  &.icn-video {
    @include image-png("icons/icn_video", 46px, 46px);
  }
  &.icn-aviation {
    @include image-png("icons/icn_aviation", 80px, 80px);
  }
  &.icn-industry {
    @include image-png("icons/icn_industrie", 80px, 80px);
  }
  &.icn-facility {
    @include image-png("icons/icn_facility", 80px, 80px);
  }

  //Identity
  &.icn-tooltip {
    @include image-png("icons/icn_tooltip", 6px, 8px, 6px, 16px);
    background-position: 0 0;
  }
  &.icn-tooltip-close {
    @include image-png("icons/icn_tooltip", 6px, 8px, 6px, 16px);
    background-position: 0 -8px;
    &.no-close {
      @include image-png("icons/icn_tooltip", 6px, 8px, 6px, 16px);
      background-position: 0 0;
    }
  }
  &.icn-status-ok {
    @include image-png("icons/icn-status", 15px, 15px, 15px, 30px);
    background-position: 0 -15px;
  }
  &.icn-status-error {
    @include image-png("icons/icn-status", 15px, 15px, 15px, 30px);
    background-position: 0 0;
  }
  &.icn-dropdown {
    @include image-png("icons/chosen-sprite", 10px, 10px, 10px, 20px);
    background-position: 0 0;
  }
}
