.text-columns {
    padding-bottom: 40px;

    p {
        @extend .bodytext;
    }

    @include breakpoint(medium up) {
        .hidden-content{
            display: block!important; 
        }
        .toggle-txt{
            display: none;
        }
    }
}

.accordion {
    .text-columns {
        padding-bottom: 0;
        
        p {
            @extend .paragraph;
        }
    }
}