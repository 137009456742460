
/*
*   DEFAULT
*/

body {

}
.bg-green-test{
    color: $white;
}


/*
*   HEADERS
*/

h1, .h1 {
    /*    font-size: rem-calc(28);*/
    line-height:rem-calc(34);
    font-weight: bold;
    color: $green;
    margin-bottom: 10px;
}

h2, .h2 {
    font-weight: bold;
    line-height:rem-calc(28);
}

h3, .h3 {
    line-height: rem-calc(28);
}

h4, .h4 {
    font-weight: bold;
}

h5 {

}

h6 {

}



/*
*   PARAGRAPHS
*/

p {
    margin-bottom: 0;
    // Text paragraphs
    &.bodytext {
        line-height: rem-calc(24);
        margin-bottom: 0.5rem;
    }
    &.paragraph{
        line-height: rem-calc(24);
        margin-bottom: 1.75rem;
    }
}

.small{
    font-size: rem-calc(14);
    line-height: rem-calc(20);
    display: block;
    &.bodytext, &.paragraph{
        line-height: rem-calc(20);
    }
}

.bold{
    font-weight: bold;
}

.normal{
    font-weight: normal;
}

/*
*   LINKS AND BUTTONS
*/

a {
    color: $green;
    &:hover, &:focus, &:active{
        color: $green;
    }
}

.bg-green-test{
    a{
        color: $white;
        &:hover, &:focus, &:active{
            color: $white;
            text-decoration: underline;
        }
    }
}

ul{
    margin: 0;
    li{
        list-style: none;
    }
    &.bullets{
        margin-left: 35px;
        text-indent: -20px;
        margin-bottom: 1.75rem;
        li{
            &:before{
                content:'';
                width: 10px;
                height: 10px;
                display: inline-block;
                background-color: $green;
                margin-right: 10px;
            }
        }
    }
    &.points{
        li{
            padding-left: 10px;
            position: relative;

            &:before{
                content:'•';
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
}


/*
*   Index-Headline
*/
.headline{
    &.index{
        margin-top: rem-calc(10);
        margin-bottom: rem-calc(50);
    }
}