body * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:focus {
    outline: none;
  }
}

.bg-grey-test {
  background-color: $grey-20;
}

.bg-green-test {
  background-color: $green;
}

.padding-top-test {
  padding-top: 20px;
}

/*
*   WRAPPER
*/

/* Läuft bis Browserrand */
.wrapper-fullwidth {
  margin-left: -map-get($wrapper-page-padding, small);
  margin-right: -map-get($wrapper-page-padding, small);

  @include breakpoint(medium up) {
    margin-left: -map-get($wrapper-page-padding, medium);
    margin-right: -map-get($wrapper-page-padding, medium);
  }
}

/* Sorgt für gewünschten Abstand zum Browser*/
.wrapper-page {
  width: 100%;
  //height: 100vh;
}

/*
*   HELPER CLASSES
*/

.hidden-content {
  display: none;
}

.pos-rel {
  position: relative;
}

.pr-0 {
  padding-right: 0;
}

.pl-0 {
  padding-left: 0;
}

.margin-top-20 {
  margin-top: rem-calc(20);
}

/*
*   HEADLINE COLORS (später über CMS einzustellen)
*/

.font-grey-80 {
  color: $grey-80 !important;
}

/*
*   TOGGLE MENU
*/

.toggle-menu {
  position: relative;

  .action-open {
    display: none !important;
    //display:inline-block!important;
  }

  &.active {
    .action-open {
      //display:none!important;
      display: inline-block !important;
    }
    .action-close {
      //display:inline-block!important;
      display: none !important;
    }
  }
}

/* DIVERSES */

label {
  min-height: 25px;
}
