/*
/
/ Tooltip / Errormessage / Inputstatus
/
*/

.tooltip {
  background-color: $grey-80;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: block;

  &:hover {
    background-color: $grey-60;
  }

  .icon {
    @include absolute-center;
  }
}

.message {
  @extend .small;
  line-height: 1rem;

  background-color: $grey-80;
  color: $white;
  padding-top: rem-calc(7);
  padding-bottom: rem-calc(7);
  padding-left: rem-calc(10);
  padding-right: rem-calc(10);
  min-height: 44px;
  margin-top: rem-calc(20);
  margin-bottom: rem-calc(10);
  z-index: 2;

  display: none;

  @include breakpoint(medium up) {
    max-width: 310px;
    &.kpno-tooltip {
      max-width: 500px;
    }
    width: 100%;
    margin-top: rem-calc(10);

    position: absolute;
    right: -16px;
    transform: translate(100%, 0%);
    top: -25px;
  }

  @include breakpoint(xlarge up) {
    width: 310px;
    &.kpno-tooltip {
      width: 500px;
    }
  }

  img {
    margin-top: rem-calc(10);
    margin-bottom: rem-calc(5);
    display: block;
  }

  &.error {
    background-color: $red-error;
    z-index: 1;
    margin-top: rem-calc(10);
    width: calc(100% - 35px);

    position: relative;
    top: inherit;
    right: inherit;
    transform: inherit;

    display: table;
    height: 1px; //Fix für Firefox, sonst gibt es keine min-height

    .v-center {
      vertical-align: middle;
      display: table-cell;
    }

    @include breakpoint(medium) {
      max-width: none;
      width: calc(100% + 20px);
      margin-bottom: 0;
    }

    @include breakpoint(xlarge up) {
      width: 310px;
    }

    &.triangle-tooltip {
      &:before {
        border-color: transparent transparent $red-error transparent;

        @include breakpoint(medium up) {
          border-color: transparent $red-error transparent transparent;
        }
      }
    }
  }
}

.tooltip {
  &.input-height {
    //Tooltip/Status vertikale auf Input/Button zentrieren
    margin-top: 1rem;
  }
}
.message {
  &.input-height {
    margin-bottom: 10px;
    //Tooltip vertikale auf Input/Button zentrieren
    @include breakpoint(medium up) {
      margin-top: rem-calc(25);
    }

    &.error {
      //Tooltip vertikale auf Input/Button zentrieren
      @include breakpoint(medium up) {
        margin-top: rem-calc(26);
      }
    }
  }
}

.triangle-tooltip {
  @include breakpoint(small only) {
    position: relative;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -9px;
    left: calc(50% - 9px);

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 10px 9px;
    border-color: transparent transparent $grey-80 transparent;
    -webkit-transform: rotate(360deg);

    @include breakpoint(medium up) {
      //   top: calc((44px / 2) - (16px / 2));
      top: rem-calc(12);
      left: -9px;
      left: -9px;
      border-width: 9px 10px 9px 0;
      border-color: transparent $grey-80 transparent transparent;
    }
  }
  &.kpno-tooltip {
    &:before {
      @include breakpoint(medium up) {
        top: calc((400px / 2) - (16px / 2));
      }
    }
  }
}

.hidden-content {
  display: none;
}

//Tooltip per Dropdown
.dropdown-pane.is-open {
  @include breakpoint(large) {
    //transform: translate(100%, 0%);
    //margin-top: -30px;
    //margin-left: 30px;
  }
}

.dropdown-pane {
  width: 100%;
  @include breakpoint(large) {
    width: 310px;
  }
}
