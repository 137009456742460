/*
*   LINKS AND BUTTONS
*/

a {
  color: $green;
  &:hover {
    color: $grey-80;
  }
}

.iconlink {
  font-weight: bold;
  .icon {
    margin-right: 10px;
    //Ausnahme: Icon sitzt links von Text
    &.icn-arrow-medium-updown {
      margin-right: 0;
      margin-left: 10px;
    }
    &.up,
    &.less {
      background-position-x: -20px;
    }
  }
  &:hover,
  &:hover:focus {
    .icon {
      background-position-y: -10px;
    }
  }
  &:focus {
    .icon {
      background-position: 0 0;
    }
  }
  &.grey-80 {
    color: $grey-80;
    .icon {
      background-position-y: -10px;
    }
    &:hover {
      color: $green;
      .icon {
        background-position-y: 0;
      }
    }
  }
}
.iconlink-arrow {
  @extend .iconlink;

  &::before {
    content: "";
    @extend .icon;
    @extend .icn-arrow-medium-right;
    margin-right: 10px;
  }
  &:hover {
    &::before {
      background-position: 0 -10px;
    }
  }
}

button,
.button {
  padding: 10px 30px 10px 20px;
  font-size: rem-calc(22);
  font-weight: bold;
  /*    margin: 10px 0;*/
  display: inline-block;
  // overflow: hidden;
  text-align: left;
  width: 100%;
  &.green {
    background-color: $green;
    color: $white;
    &:hover {
      cursor: pointer;
      background-color: $grey-80;
      color: $white !important;
    }
  }
  &.whiteblue {
    background-color: $white;
    color: $blue;
    &:hover {
      cursor: pointer;
      background-color: $grey-80;
      color: $white !important;
    }
  }
  &.green-40 {
    background-color: $green-40;
    color: $green;
    &:hover {
      cursor: pointer;
      background-color: $grey-80;
      color: $white !important;
    }
  }
  &.whitered {
    background-color: $white;
    color: $red;
    &:hover {
      cursor: pointer;
      background-color: $grey-80;
      color: $white !important;
    }
  }
  &.onwhite {
    @include cut-corner($white, 20px);
  }
  &.ongrey-20 {
    @include cut-corner($grey-20, 20px);
  }
  &.ongreen {
    @include cut-corner($green, 20px);
  }
  &.onblue {
    @include cut-corner($blue, 20px);
  }
  &.onred {
    @include cut-corner($red, 20px);
  }

  &.small,
  &.xsmall {
    padding: 5px 15px 5px 10px;
    &.onwhite {
      @include cut-corner($white, 12px);
    }
    &.ongrey-20 {
      @include cut-corner($grey-20, 12px);
    }
    &.ongreen {
      @include cut-corner($green, 12px);
    }
    &.onblue {
      @include cut-corner($blue, 12px);
    }
    &.onred {
      @include cut-corner($red, 12px);
    }
  }
  &.small {
    font-size: rem-calc(16);
  }
  &.xsmall {
    font-size: rem-calc(14);
  }
}

// Button classes for rte
.button-xsmall {
  @extend .button;
  @extend .xsmall;
}

// Remove outlines for mouse input
html[data-whatinput="mouse"] {
  button {
    &:focus {
      outline: 0;
    }
  }
}

.textlink {
  font-size: rem-calc(16);
  line-height: 1.6;
  text-decoration: underline;
  color: $grey-80;
}
