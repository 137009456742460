.main-content {
  position: relative;

  padding-right: rem-calc(35); //Platz für Tooltipp / Status

  .tooltip {
    position: absolute;
    right: 0;
    top: 0;
  }
  &.error {
    .checkmark {
      border-color: $red-error;
    }
  }
}

.status {
  position: absolute;
  right: 0;
  //   top: calc(25px + ((44px / 2) - (10px / 2)));
  top: rem-calc(39px);
  .icn-status-ok {
    display: none;
  }
  .icn-status-error {
    display: none;
  }
}

.no-padding {
  .status {
    right: -35px;
  }
}

.error {
  .status {
    .icn-status-error {
      display: block;
    }
  }
}

.success {
  .status {
    .icn-status-ok {
      display: block;
    }
  }
}
.password-strength {
  display: none;
}

.form-identity {
  //Eingerückte Tooltips in Form
  .main-content {
    .tooltip {
      top: 0px;
      right: 35px; //Rückt Tooltip ans Ende des Inputs/buttons
    }
    &.inline {
      padding-right: 0px;
    }
  }

  .message {
    @include breakpoint(medium up) {
      right: 20px;
    }
  }
  .message:not(.error) {
    @include breakpoint(medium up) {
      top: -20px;
      &.kpno-tooltip {
        top: -200px;
      }
    }
  }

  .button {
    //margin-top: rem-calc(10);
  }

  .headline {
    font-size: 1rem;
    font-weight: bold;
    margin-top: rem-calc(25);
    margin-bottom: rem-calc(25);

    &.first {
      margin-top: 0;
    }

    &.margin-bottom-0 {
      margin-bottom: 0;
    }
  }

  .info {
    font-size: rem-calc(14);
  }

  .no-padding {
    .columns:nth-child(1) {
      padding-right: 0.5rem;
    }
    .columns:nth-child(2) {
      padding-left: 0.5rem;
      padding-right: 3.5rem;
      @include breakpoint(small down) {
        padding-right: 2.8rem;
      }
    }

    .tooltip-2 {
      .tooltip {
        right: 0px;
      }
      .message {
        right: -14px;
        @include breakpoint(small down) {
          right: 0;
        }
      }
    }
  }

  .toggle-radio {
    .container-input {
      display: none;
    }
    &.active {
      .container-input {
        display: block;
        margin-bottom: 20px;
      }
    }
    .main-content {
      .container-radio {
        &.forgot-pw-radio-e-mail {
          margin-top: rem-calc(20);
        }
        &.forgot-pw-radio-sms {
          margin-top: rem-calc(0);
        }
      }
      &.error {
        .container-radio {
          &.forgot-pw-radio-e-mail {
            margin-top: rem-calc(20);
          }
          &.forgot-pw-radio-sms {
            margin-top: rem-calc(20);
          }
        }
      }
    }
  }

  .message-radio {
    .status {
      top: 6px;
    }
    .message {
      &.input-height {
        //Tooltip vertikale auf Input/Button zentrieren
        @include breakpoint(medium up) {
          margin-top: rem-calc(12);
        }

        &.error {
          //Tooltip vertikale auf Input/Button zentrieren
          @include breakpoint(medium up) {
            margin-top: rem-calc(12);
          }
        }
      }
    }
  }
}

.tooltip {
  &.active {
    pointer-events: inherit;
  }
}
