
.language-menu{
    max-width: 220px;
    background-color: $grey-20;
    border: 1px solid $light-gray;
    width: 100%;
    margin-top: 20px;

    @include breakpoint(medium up) {
        float: right;
        margin-top: 0;
    }

    font-size: rem-calc(14);
    color: $dark-gray;

    a{
        color: $dark-gray;
        display: block;

        &:hover{
            color: $green;
        }
    }
}


.dropdown.menu{
    &.language-menu{
        > li{
            &.is-dropdown-submenu-parent{
                > a{
                    &:after {
                        right: 10px;
                        border: none;
                        margin-top: -5px;

                        @include image-png('icons/chosen-sprite', 10px, 10px, 10px, 20px);
                        background-position: 0 0;
                    }
                }
            }

            &.opens-right{
                > .is-dropdown-submenu {
                    left: -21px;
                }
            }
        }

        .is-active>a {
            color: $dark-gray;
        }

        a{
            padding-left: 45px;
            padding-top: 7px;
            padding-bottom: 7px;

            position: relative;
        }
    }
}

.is-dropdown-submenu {
    min-width: 220px;
    background-color: $grey-20;
    border: 1px solid $light-gray;
}


.flag {
    width: 46px;
    height: 40px;
    transform: scale(0.5);

    position: absolute;
    top: -4px;
    left: 0;
}




