
.password-strength{
    
    margin-bottom: rem-calc(20);
    
    @include breakpoint(medium up) {
        margin-bottom: 0;
        margin-top: rem-calc(30);
        margin-left: -20px;
    }
    
    ul{
        @include clearfix;
        margin-top: 3px;

        li{
            float: left;
            width: 30px;
            height: 15px;
            border-right: 2px solid $white;
            background-color: $grey-20;

            &:last-child{
                border-right: none;
            }
        }

        &.save-1{
            li{
                &:nth-child(1){
                    background-color: $red-error;
                }
            }
        }

        &.save-2{
            li{
                &:nth-child(1),
                    &:nth-child(2){
                    background-color: $red-error;
                }
            }
        }

        &.save-3{
            li{
                &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3){
                    background-color: $orange;
                }
            }
        }

        &.save-4{
            li{
                &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4){
                    background-color: $green;
                }
            }
        }

        &.save-5{
            li{
                &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5){
                    background-color: $green;
                }
            }
        }
    }
}