
.back-link{
    border-bottom: 1px solid $grey-20;
    //text-align: right;
    padding-bottom: 15px;
    margin-bottom: 0;
    &.bottom{
        border-bottom: 0;
        border-top: 1px solid $grey-20;
        padding-bottom: 0;
        padding-top: 15px;
        margin-top: 15px;
    }
}