// SITE HEADER

.site-header {

    position: relative;
    border-bottom: 1px solid $grey-20;
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(10);

    .logo-bar{
        padding-top: 10px;
        padding-bottom: 10px;
/*        @include breakpoint(medium up) {
            padding-top: 20px;
            padding-bottom: 5px;
        }*/

        .logo{
            @include image-png('logo_wisag', 117px, 36px);
            float: left;
            @include clearfix;
        }

    }

    .search-bar{
        position: relative;
        width: 100%;
        min-height: 40px;
        height: auto;

        button {
            padding: 0;
            cursor: pointer;

            &:focus {
                outline: none;
            }
        }

        .toggle-menu {

            button {
                position: absolute;
                width:25px;
                height: 40px;
                top: 0;

                &.action-open {
                    display: block !important;
                    text-align: left;
                    top: -1px;
                }

                &.action-close {
                    right: 47px;
                    z-index: 1;
                    top: -2px;
                    .icon {
                        background-position: 0 -24px;
                    }
                }
            }
        }
        .search-container{
            position: relative;
            button{
                width: 25px;
                height: 100%;
                padding: 0;
                color: transparent;
                cursor: pointer;
                position: absolute;
                left: 0;
                top: -1px;
                text-align: left;
            }
        }
        .toggle-search{
            position: static;
            &.active{
                .border-right{
                    position: absolute;
                    top: 0;
                    right: 36px;
                    width: 1px;
                    height: 40px;
                    background-color: $grey-20;
                }
            }
        }
    }

    .nav-bar{
        border-top: 1px solid $grey-20;
        .nav-main{
            position: relative;

            ul{
                margin: 0;
                float: right;
                @include clearfix;
                li{
                    list-style: none;
                    text-align: right;
                    color: $grey-80;
                    font-size: $size-14;
                    padding: 3px 0;
                    font-weight: bold;
                    a{
                        color: $grey-80;
                        &:hover{
                            color: $green;
                        }
                    }
                    &:last-child{
                        padding-bottom: 20px;
                    }

                    &.active {
                        a {
                            color: $green;
                        }
                    }
                }
            }
        }

        .toggle-menu{
            position: absolute;
            top: 71px;
            right: 10px;
        }

    }

    .navigation-container{
        display: none;
        padding-bottom: 15px;
        padding-top: 10px;
        &.active{
            border-bottom: 1px solid $grey-20;

        }
    }

    .nav-meta{
        ul{
            margin: 0;
            float: right;
            @include clearfix;
            li{
                list-style: none;
                font-size: $size-14;
                float: left;
                @include clearfix;
                padding: 0 10px;
                @include breakpoint(medium up) {
                    padding: 0 7px;
                }
                @include breakpoint(large up) {
                    padding: 0 10px;
                }
                &:first-child{
                    padding-left: 0;
                }
                &:last-child{
                    padding-right: 0;
                }
                a{
                    color: $grey-60;
                    &:hover{
                        color: $grey-80;
                        .icon{
                            background-position: 0 -10px;
                        }
                    }
                }
                .icon{
                    margin-right: 10px;
                    background-position: 0 -20px;
                }
            }
        }

    }

    .socials{
        position: absolute;
        right: 10px;
        top: 0;
        overflow: hidden;
        @include breakpoint(medium up) {
            top: -20px;
        }
        ul{
            margin: 0;
            @include cut-corner($white, 12px);
            background-color: $grey-20;
            padding: 4px 15px 2px 15px;
            li{
                list-style: none;
                display: inline-block;
                padding: 0 5px;
                a{
                    .icon{
                        &:hover{
                            background-position: 0 -15px;
                        }
                    }
                }
            }
        }
    }



    &.mobile{
        display: block;
        @include breakpoint(medium up) {
            display: none;
        }
    }

    &.desktop{
        display: none;
        @include breakpoint(medium up) {
            display: block;
        }

        .search-bar{
            height: auto;
            width: auto;
            float: left;
            @include clearfix;
            width: 100%;
            margin-top: 16px;
            .toggle-menu {
                .icon {
                    margin-top: 0;

                    &.icn-close{
                        top: 13px;
                        z-index: 10;
                        right: 11px;
                    }
                }       

                button {
                    position: absolute;
                    width: 32px;
                    height: 35px;
                    top: -1px;

                    &.action-open {
                        /*                        padding-left: 1px;*/
                        text-align: center;
                        left: 0;
                    }

                    &.action-close {
                        right: 0;
                        z-index: 1;
                    }

                    .icon {
                        position: static;
                    }
                }
            }

            .search-container{
                button {
                    width: 32px;
                    text-align: center;
                }
            }
        }

        .nav-bar{
            padding-top: 5px;
            padding-bottom: 5px;
            border-bottom: 1px solid $grey-20;
            .nav-main{
                li{
                    display: inline-block;
                    padding: 0 10px;
                    &:first-child{
                        padding-left: 0;
                    }
                    &:last-child{
                        padding-right: 0;
                        padding-bottom: 0;
                    }
                    @include breakpoint(large up) {
                        padding: 0 15px;
                    }
                }
            }
        }

        .nav-meta{
            margin-top: 25px;
        }

    }


    &.reduced{
        margin-bottom: 15px;
    }
}